import React from "react";
import { FormattedMessage, injectIntl } from 'react-intl';

import withLayout from '../layout';
import SEO from "../components/seo"


const NotFoundPage = (props) => {
    const { intl } = props;
    const titleText = intl.formatMessage({id: 'error404.error-404-title'});

    return (
        <div>
            <SEO title={titleText} />
            <h1><FormattedMessage id="error404.error-404" /></h1>
            <p><FormattedMessage id="error404.error-404-explanation" /></p>
        </div>
    );
}

const customProps = {
    localeKey: '404',
};

export default withLayout(customProps)(injectIntl(NotFoundPage));
